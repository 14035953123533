.profile-card {
  width: 23%;
  height: 80vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 40px 0;
  gap: 20px;
  position: fixed;
  .profile-photo {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    background-color: #d8d3d3;
    position: relative;
    img {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      padding: 8px;
      background-color: rgb(164, 161, 161);
    }
    .online-indicator {
      position: absolute;
      bottom: 10px;
      right: 15px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      .status-indicator {
        // border: 2px solid;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin: 1px;
      }
      .online {
        background-color: #22c55e; /* Green for online */
      }

      .offline {
        background-color: #e74747; /* Red for offline */
      }
    }
  }
  .profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 22px;
      margin-bottom: 15px;
    }
    .contact-info {
      font-size: 16px;
      margin-top: 4px;
      color: #6b7280;
    }
    .contact-methods {
      display: flex;
      gap: 20px;
      margin-top: 20px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
